import React, { useState, useEffect, Fragment } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { StaticQuery, graphql, Link } from 'gatsby'
import MenuItemDropdown from './MenuItemDropdown'
import ContactFormModal from './ContactFormModal'
import Logo from './logo-svg'
import Button from './atoms/Button'
import * as NavigationMenu from '@radix-ui/react-navigation-menu'
import { ChevronLeft, ChevronDown, X } from 'react-feather'

function Navbar({ headerTransparent = false, shadow = true }) {
  const companyname = 'KYPD Kentucky Plumbing'
  const description = 'desc'
  const slogan = '#1 Renovation Company KY'
  const subtagline = '24 Hour Emergency Service'
  const phonenumber = '(859) 823-5973'
  const mainnavigationstyle = 'inline'

  const [mobileMenuOpen, setMobileMenuOpen] = useState(false)
  const [contactFormOpen, setContactFormOpen] = useState(false)

  useEffect(() => {
    if (mobileMenuOpen) {
      document.body.classList.add('menu-expanded')
    }

    return function cleanup() {
      document.body.classList.remove('menu-expanded')
    }
  }, [mobileMenuOpen])

  const MenuItem = ({ item, source, headerTransparent }) => {
    let itemId = item.id
    let title = item.name
    let slug = item.url

    if (item.submenu && item.submenu.length > 0) {
      return (
        <MenuItemDropdown
          item={item}
          label={title}
          type={1}
          source={source}
          headerTransparent={headerTransparent}
        />
      )
    }
    return (
      <Link
        to={`${slug && slug.length < 2 ? '/' : slug}`}
        activeClassName={`${headerTransparent ? "bg-white/5" : 'bg-gray-100'}`}
        className={`${source && source == 'mobilemenu'
          ? 'block py-4 px-8 font-medium border-b border-gray-100'
          : `px-4 py-3 rounded-xl  ${headerTransparent
            ? 'text-white '
            : 'text-gray-800   '
          }`
          }  font-medium text-base   transition-colors duration-150 ease-in  `}
      >
        {item.name}
      </Link>
    )
  }

  const ListItem = React.forwardRef(
    ({ className, children, title, ...props }, forwardedRef) => (
      <NavigationMenu.Link asChild>
        <Link
          className={`ListItemLink ${className}`}
          {...props}
          ref={forwardedRef}
        >
          <div className="ListItemHeading">{title}</div>
          <p className="ListItemText">{children}</p>
        </Link>
      </NavigationMenu.Link>
    )
  )

  return (
    <StaticQuery
      query={graphql`
        query SiteTitleQueryInNav {
          site {
            siteMetadata {
              title
              description
              companyInfo {
                phone
                email
                addressLine1
                addressLine2
                city
                state
                zip
                license
              }
            }
          }
          allContentfulNavigation(filter: { name: { eq: "Main Navigation" } }) {
            edges {
              node {
                id
                name
                menuItems {
                  id
                  name
                  title
                  url
                  openInNewWindow
                  submenu {
                    id
                    name
                    menuItems {
                      id
                      name
                      title
                      url
                      openInNewWindow 
                    }
                  }
                }
                campaign
              }
            }
          }
        }
      `}
      render={(data) => {
        const items = data.allContentfulNavigation.edges[0].node.menuItems
        return (
          <>
            <header
              className={`  ${headerTransparent ? 'header--transparent-main' : ''
                }`}
            >
              <div
                className={`${headerTransparent
                  ? ' bg-gray-100 text-brand-900'
                  : ' bg-gray-100 text-brand-900'
                  } transition-all duration-200   font-display  block relative z-20`}
              >
                <div className="flex justify-center md:justify-between  font-display mx-auto py-3 text-xs md:text-sm max-w-7xl  px-4 md:px-6">
                  <span className="block text-center md:text-left font-medium border-l border-l-4 border-accent-500 pl-2">
                    Top Rated Plumbing Contractor in Lexington, KY
                  </span>
                  <span className="hidden md:flex text-center md:text-left font-normal flex items-center">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" className='hidden mr-1 w-5 h-5 text-brand-400'> <path fill="currentColor" d="M385.1 419.1C349.7 447.2 304.8 464 256 464s-93.7-16.8-129.1-44.9l80.4-80.4c14.3 8.4 31 13.3 48.8 13.3s34.5-4.8 48.8-13.3l80.4 80.4zm68.1 .2C489.9 374.9 512 318.1 512 256s-22.1-118.9-58.8-163.3L465 81c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0L419.3 58.8C374.9 22.1 318.1 0 256 0S137.1 22.1 92.7 58.8L81 47c-9.4-9.4-24.6-9.4-33.9 0s-9.4 24.6 0 33.9L58.8 92.7C22.1 137.1 0 193.9 0 256s22.1 118.9 58.8 163.3L47 431c-9.4 9.4-9.4 24.6 0 33.9s24.6 9.4 33.9 0l11.8-11.8C137.1 489.9 193.9 512 256 512s118.9-22.1 163.3-58.8L431 465c9.4 9.4 24.6 9.4 33.9 0s9.4-24.6 0-33.9l-11.8-11.8zm-34.1-34.1l-80.4-80.4c8.4-14.3 13.3-31 13.3-48.8s-4.8-34.5-13.3-48.8l80.4-80.4C447.2 162.3 464 207.2 464 256s-16.8 93.7-44.9 129.1zM385.1 92.9l-80.4 80.4c-14.3-8.4-31-13.3-48.8-13.3s-34.5 4.8-48.8 13.3L126.9 92.9C162.3 64.8 207.2 48 256 48s93.7 16.8 129.1 44.9zM173.3 304.8L92.9 385.1C64.8 349.7 48 304.8 48 256s16.8-93.7 44.9-129.1l80.4 80.4c-8.4 14.3-13.3 31-13.3 48.8s4.8 34.5 13.3 48.8zM208 256a48 48 0 1 1 96 0 48 48 0 1 1 -96 0z" /></svg>
                    {subtagline}
                  </span>
                </div>
              </div>
              <div
                className={`${headerTransparent
                  ? ' md:bg-transparent bg-transparent md:-mb-[90px]  '
                  : 'bg-white'
                  } ${!shadow ? '' : 'md:shadow-sm '} relative z-20`}
              >
                <div className="flex justify-between items-center px-4 md:px-6 py-4 lg:py-0 md:space-x-10 max-w-7xl mx-auto md:h-[90px]">
                  <Link
                    to="/" className='flex items-center justify-center -my-3'>
                    <span
                      className="block w-44  py-2 shrink-0 "
                      title={companyname}
                    >
                      <Logo
                        classes={
                          headerTransparent ? 'text-white' : 'text-brand-500'
                        }
                      />

                    </span>
                  </Link>

                  {/* <NavigationMenu.Root className="NavigationMenuRoot  hidden lg:block">
                    <NavigationMenu.List
                      className={`flex justify-center items-center  ${headerTransparent ? 'text-white' : 'text-brand-500'
                        }`}
                    >
                      {items.map((item, i) => {
                        if (item.submenu) {
                          return (
                            <NavigationMenu.Item>
                              <NavigationMenu.Trigger className="NavigationMenuTrigger">
                                {item.submenu[0].name}{' '}
                                <ChevronDown
                                  size={15}
                                  className="CaretDown text-sm"
                                  aria-hidden
                                />
                              </NavigationMenu.Trigger>
                              <NavigationMenu.Content className="NavigationMenuContent">
                                <ul className="List one">
                                  {item.submenu[0].menuItems.map((item, i) => {
                                    return (
                                      <ListItem
                                        style={{ gridRow: 'span 2' }}
                                        title={item.name}
                                        to={item.url}
                                      />
                                    )
                                  })}
                                </ul>
                              </NavigationMenu.Content>
                            </NavigationMenu.Item>
                          )
                        }
                        return (
                          <NavigationMenu.Item key={item.id}>
                            <NavigationMenu.Link className="NavigationMenuLink">
                              <Link to={item.url} className="py-8">
                                {item.name}
                              </Link>
                            </NavigationMenu.Link>
                          </NavigationMenu.Item>
                        )
                      })}

                      <NavigationMenu.Indicator className="NavigationMenuIndicator">
                        <div className="Arrow" />
                      </NavigationMenu.Indicator>
                    </NavigationMenu.List>

                    <div className="ViewportPosition">
                      <NavigationMenu.Viewport className="NavigationMenuViewport" />
                    </div>
                  </NavigationMenu.Root> */}

                  {mainnavigationstyle == 'inline' ? <div className="items-center font-display md:space-x-1 hidden lg:flex text-base">
                    {items.map((item, i) => {
                      return <MenuItem key={item.id} item={item} type={item.type} headerTransparent={headerTransparent} />;
                    })}
                  </div> : null}

                  {mainnavigationstyle == 'stacked' ? (
                    <div className="hidden lg:block font-display text-center">
                      <span className="font-medium block">{slogan}</span>
                      <span className="font-normal text-sm text-gray-800 block">
                        {subtagline}
                      </span>
                    </div>
                  ) : null}

                  <div className="hidden md:block font-display shrink-0">
                    <div className="flex flex-row lg:flex-col xl:flex-row items-center">
                      <div className="pr-8 lg:pr-0 xl:pr-8 text-center">
                        <a
                          className={`block font-bold text-lg md:text-xl lg:text-lg xl:text-2xl tracking-tight ${headerTransparent ? 'text-white' : 'text-brand-600'
                            }`}
                          href={`tel:${phonenumber}`}
                        >
                          {phonenumber}
                        </a>
                      </div>
                      <div>
                        <Button
                          onClick={() => setContactFormOpen(true)}
                          className="button-active-bhvr whitespace-no-wrap block cursor-pointer transition duration-150 ease-in-out bg-brand-500 focus:bg-brand-600 hover:bg-brand-600 focus:outline-none font-medium px-4 py-2 lg:py-3 lg:px-5   text-white font-display"
                        >
                          Request a Quote
                        </Button>
                        <ContactFormModal
                          modalOpen={contactFormOpen}
                          onModalClose={setContactFormOpen}
                        />
                      </div>
                    </div>
                  </div>

                  <div
                    className={`-mr-2 -my-2 ${mainnavigationstyle == 'stacked'
                      ? 'md:hidden'
                      : 'lg:hidden'
                      }`}
                  >
                    <button
                      type="button"
                      onClick={() => setMobileMenuOpen(!mobileMenuOpen)}
                      className={`${headerTransparent
                        ? 'text-white'
                        : ' text-gray-600 hover:bg-gray-200  focus:text-gray-700  focus:bg-gray-50'
                        } text-center p-2 rounded-lg  focus:outline-none  transition duration-150 ease-in`}
                    >
                      {mobileMenuOpen ? (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="currentColor"
                          className="h-6 w-6 mx-auto"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M6 18L18 6M6 6l12 12"
                          />
                        </svg>
                      ) : (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="currentColor"
                          className="h-6 w-6 mx-auto"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M3.75 9h16.5m-16.5 6.75h16.5"
                          />
                        </svg>
                      )}

                      <span
                        className="block uppercase font-normal tracking-wide"
                        style={{ fontSize: '.5rem' }}
                      >
                        {mobileMenuOpen ? 'Close' : 'Menu'}
                      </span>
                    </button>
                  </div>
                </div>

                {/* <Transition
                  show={mobileMenuOpen}
                  enter="duration-200 ease-out"
                  enterFrom="opacity-0 scale-95"
                  enterTo="opacity-100 scale-100"
                  leave="duration-100 ease-in"
                  leaveFrom="opacity-100 scale-100"
                  leaveTo="opacity-0 scale-95"
                  className={`absolute inset-x-0 transition transform origin-top-right z-50 ${mainnavigationstyle == 'stacked' ? 'md:hidden' : 'lg:hidden'}`}
                  style={{ top: '100%' }}
                >
                  <div className=" shadow-lg font-display">
                    <div className="shadow-xs bg-white">

                      <div>
                        {items.map((item, i) => {
                          return <MenuItem key={i} item={item} type={item.type} source={'mobilemenu'} />;
                        })}
                      </div>
                    </div>
                  </div>
                </Transition> */}
              </div>

              {mainnavigationstyle == 'stacked' ? (
                <div className="relative bg-white text-white md:shadow-sm md:border-none z-10 hidden md:block font-display">
                  <div className="flex items-center px-4 md:px-6 md:space-x-10 container mx-auto ">
                    {items.map((item, i) => {
                      return <MenuItem key={i} item={item} type={item.type} />
                    })}
                  </div>
                </div>
              ) : null}
            </header>
            <div
              className={`sticky top-0  z-20 md:hidden py-1 px-2 ${headerTransparent ? '-mb-48' : 'bg-brand-500'
                }`}
            >
              <div
                className={` shadow-sm md:border-none ${headerTransparent
                  ? 'shadow-lg bg-brand-600/80 backdrop-blur-lg rounded-2xl text-brand-900 '
                  : 'bg-brand-500  text-kypdlight-900 '
                  }`}
              >
                <div className="flex  items-center justify-between px-4 md:px-6  py-3 container mx-auto ">
                  <div>
                    <p className="mb-0 text-white font-display hidden sm:block">
                      Give Us A Call Today
                    </p>
                    <a
                      className={`block font-semibold text-xl font-display ${headerTransparent
                        ? 'text-brand-100 '
                        : 'text-brand-100 '
                        }`}
                      href={`tel:${phonenumber}`}
                    >
                      {phonenumber}
                    </a>
                  </div>
                  <Button
                    color='secondary'
                    onClick={() => setContactFormOpen(true)}
                    size="small"
                    className="py-1 -my-1"
                  >
                    Request a Quote
                  </Button>
                </div>
              </div>
            </div>

            <Transition.Root show={mobileMenuOpen} as={Fragment}>
              <Dialog
                as="div"
                className="relative z-20"
                onClose={setMobileMenuOpen}
              >
                <Transition.Child
                  as={Fragment}
                  enter="ease-in-out duration-500"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in-out duration-500"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="fixed inset-0 bg-black bg-opacity-50 transition-opacity" />
                </Transition.Child>

                <div className="fixed inset-0 overflow-hidden">
                  <div className="absolute inset-0 overflow-hidden font-display">
                    <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
                      <Transition.Child
                        as={Fragment}
                        enter="transform transition ease-in-out duration-500 sm:duration-700"
                        enterFrom="translate-x-full"
                        enterTo="translate-x-0"
                        leave="transform transition ease-in-out duration-500 sm:duration-700"
                        leaveFrom="translate-x-0"
                        leaveTo="translate-x-full"
                      >
                        <Dialog.Panel className="pointer-events-auto w-screen max-w-md">
                          <div className="flex h-full flex-col overflow-y-scroll bg-white/100  py-6 shadow-xl">
                            <div className="px-4 sm:px-6">
                              <div className="flex items-start justify-between">
                                <Dialog.Title className="text-base font-semibold leading-6 text-gray-900">
                                  <Link
                                    to="/" className='flex items-center justify-center -my-3'>
                                    <span
                                      className="block w-40  py-2 shrink-0 "
                                      title={companyname}
                                    >
                                      <Logo
                                        classes={
                                          headerTransparent ? 'text-white' : 'text-brand-500'
                                        }
                                      />

                                    </span>
                                  </Link>
                                </Dialog.Title>
                                <div className="ml-3 flex h-8 items-center">
                                  <button
                                    type="button"
                                    className="rounded-lg text-brand-700 hover:text-gray-500 focus:outline-none  "
                                    onClick={() => setMobileMenuOpen(false)}
                                  >
                                    <span className="sr-only">Close panel</span>
                                    <X className="h-6 w-6" aria-hidden="true" />
                                  </button>
                                </div>
                              </div>
                            </div>
                            <div className="relative mt-6 ">
                              {' '}
                              <div>
                                {items.map((item, i) => {
                                  return (
                                    <MenuItem
                                      key={i}
                                      item={item}
                                      type={item.type}
                                      source={'mobilemenu'}
                                    />
                                  )
                                })}
                              </div>
                            </div>
                          </div>
                        </Dialog.Panel>
                      </Transition.Child>
                    </div>
                  </div>
                </div>
              </Dialog>
            </Transition.Root>
          </>
        )
      }}
    />
  )
}

export default Navbar
